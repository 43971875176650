import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import {
  HomeIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  NewspaperIcon,
  BookmarkIcon,
  LockOpenIcon,
} from '@heroicons/react/outline';
import { FilterIcon } from '@heroicons/react/solid';
import { RouteComponentProps, withRouter } from 'react-router';
import { classNames } from '../utils/classNames';
import { Link } from 'react-router-dom';
import TruckIcon from '@heroicons/react/outline/TruckIcon';
import { useApiSlice } from '../api/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectApi } from '../api/slice/selectors';
import { AccountMenu } from './account-menu';
import { selectProfileDetail } from '../app/modules/profile/slice/selectors';
import { accountDetailDefault } from '../app/modules/profile/slice';
import {
  ADMIN_ROLE_ID,
  OPERATIONAL_ADMIN_ROLE_ID,
  OPERATIONAL_ROLE_ID,
  PARTNER_SORTER_ROLE_ID,
  SORTER_ROLE_ID,
  SUPER_ADMIN_ROLE_ID,
} from '../config/global.config';
import { MenuType } from '../types/User';
import Logo from '../assets/logo.png';
import { logger } from '../utils/logger';
import { getAuth, signOut } from 'firebase/auth';
import { LoadingOverlay, Search } from '../app/components';

const navigation: MenuType[] = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: false },
  {
    name: 'Banner',
    icon: BookmarkIcon,
    current: false,
    children: [
      { name: 'List', href: '/banner-list', current: false },
      { name: 'Create', href: '/banner/create', current: false },
    ],
  },
  {
    name: 'Drop Points',
    href: '/drop-points',
    icon: NewspaperIcon,
    children: [
      { name: 'Drop Points', href: '/drop-points', current: false },
      {
        name: 'Create Drop Points',
        href: '/drop-point/create',
        current: false,
      },
    ],
  },
  {
    name: 'Drop In',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'Drop-In Overview', href: '/drop-in', current: false },
      { name: 'Drop-In Receiving', href: '/drop-in/receive', current: false },
      { name: 'Drop-In Sorting', href: '/drop-in/sort', current: false },
    ],
  },
  {
    name: 'Rebox',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'List', href: '/rebox-list', current: false },
      { name: 'Create', href: '/rebox/create', current: false },
    ],
  },
  {
    name: 'Rebox Product',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'List', href: '/rebox-products', current: false },
      { name: 'Create', href: '/rebox-product/create', current: false },
    ],
  },
  {
    name: 'Withdraws',
    icon: NewspaperIcon,
    current: false,
    children: [
      { name: 'Overview', href: '/withdraws', current: false },
      { name: 'Fraud Management', href: '/fraud-management', current: false },
      { name: 'Threshold Setting', href: '/threshold-setting', current: false },
    ],
  },
  {
    name: 'UCO Pick-up',
    icon: TruckIcon,
    current: false,
    children: [
      {
        name: 'One Time Pick-up Request Review',
        href: '/uco-pickup',
        current: false,
      },
      {
        name: 'Routine Pick-up Request Overview',
        href: '/uco-routine-pickup',
        current: false,
      },
      { name: 'UCO Pick-up Client List', href: '/uco-client', current: false },
      {
        name: 'Jerry-can Request Review',
        href: '/uco-jerry-can',
        current: false,
      },
      {
        name: 'PWA UCO Setting',
        href: '/uco-setting',
        current: false,
      },
      {
        name: 'Setup Reminder PIC Internal',
        href: '/uco-setting-reminder',
        current: false,
      },
    ],
  },
  {
    name: 'Account Manager',
    icon: LockOpenIcon,
    current: false,
    children: [{ name: 'Account List', href: '/account-list', current: false }],
  },
];

const navigationAdmin: MenuType[] = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: false },
  {
    name: 'Banner',
    icon: BookmarkIcon,
    current: false,
    children: [
      { name: 'List', href: '/banner-list', current: false },
      { name: 'Create', href: '/banner/create', current: false },
    ],
  },
  {
    name: 'Drop Points',
    href: '/drop-points',
    icon: NewspaperIcon,
    children: [
      { name: 'Drop Points', href: '/drop-points', current: false },
      {
        name: 'Create Drop Points',
        href: '/drop-point/create',
        current: false,
      },
    ],
  },
  {
    name: 'Drop In',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'Drop-In Overview', href: '/drop-in', current: false },
      { name: 'Drop-In Receiving', href: '/drop-in/receive', current: false },
      { name: 'Drop-In Sorting', href: '/drop-in/sort', current: false },
    ],
  },
  {
    name: 'Rebox',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'List', href: '/rebox-list', current: false },
      { name: 'Create', href: '/rebox/create', current: false },
    ],
  },
  {
    name: 'Rebox Product',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'List', href: '/rebox-products', current: false },
      { name: 'Create', href: '/rebox-product/create', current: false },
    ],
  },
  {
    name: 'Withdraws',
    icon: NewspaperIcon,
    current: false,
    children: [
      { name: 'Overview', href: '/withdraws', current: false },
      { name: 'Fraud Management', href: '/fraud-management', current: false },
    ],
  },
  {
    name: 'UCO Pick-up',
    icon: TruckIcon,
    current: false,
    children: [
      {
        name: 'One Time Pick-up Request Review',
        href: '/uco-pickup',
        current: false,
      },
      {
        name: 'Routine Pick-up Request Overview',
        href: '/uco-routine-pickup',
        current: false,
      },
      { name: 'UCO Pick-up Client List', href: '/uco-client', current: false },
      {
        name: 'Jerry-can Request Review',
        href: '/uco-jerry-can',
        current: false,
      },
      {
        name: 'PWA UCO Setting',
        href: '/uco-setting',
        current: false,
      },
      {
        name: 'Setup Reminder PIC Internal',
        href: '/uco-setting-reminder',
        current: false,
      },
    ],
  },
  {
    name: 'Account Manager',
    icon: LockOpenIcon,
    current: false,
    children: [{ name: 'Account List', href: '/account-list', current: false }],
  },
];

const navigationSorter: MenuType[] = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: false },
  {
    name: 'Drop In',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'Drop-In Overview', href: '/drop-in', current: false },
      { name: 'Drop-In Receiving', href: '/drop-in/receive', current: false },
      { name: 'Drop-In Sorting', href: '/drop-in/sort', current: false },
    ],
  },
];

const navigationOperational: MenuType[] = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: false },
  {
    name: 'Banner',
    icon: BookmarkIcon,
    current: false,
    children: [
      { name: 'List', href: '/banner-list', current: false },
      { name: 'Create', href: '/banner/create', current: false },
    ],
  },
  {
    name: 'Drop Points',
    href: '/drop-points',
    icon: NewspaperIcon,
    children: [
      { name: 'Drop Points', href: '/drop-points', current: false },
      {
        name: 'Create Drop Points',
        href: '/drop-point/create',
        current: false,
      },
    ],
  },
  {
    name: 'Drop In',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'Drop-In Overview', href: '/drop-in', current: false },
      { name: 'Drop-In Receiving', href: '/drop-in/receive', current: false },
      { name: 'Drop-In Sorting', href: '/drop-in/sort', current: false },
    ],
  },
  {
    name: 'Withdraws',
    href: '/withdraws',
    icon: NewspaperIcon,
  },
  {
    name: 'UCO Pick-up',
    icon: TruckIcon,
    current: false,
    children: [
      {
        name: 'One Time Pick-up Request Review',
        href: '/uco-pickup',
        current: false,
      },
      {
        name: 'Routine Pick-up Request Overview',
        href: '/uco-routine-pickup',
        current: false,
      },
      { name: 'UCO Pick-up Client List', href: '/uco-client', current: false },
      {
        name: 'Jerry-can Request Review',
        href: '/uco-jerry-can',
        current: false,
      },
      {
        name: 'PWA UCO Setting',
        href: '/uco-setting',
        current: false,
      },
      {
        name: 'Setup Reminder PIC Internal',
        href: '/uco-setting-reminder',
        current: false,
      },
    ],
  },
];

const navigationAdminOps: MenuType[] = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: false },
  {
    name: 'Drop In',
    icon: UsersIcon,
    current: false,
    children: [
      { name: 'Drop-In Overview', href: '/drop-in', current: false },
      { name: 'Drop-In Receiving', href: '/drop-in/receive', current: false },
      { name: 'Drop-In Sorting', href: '/drop-in/sort', current: false },
    ],
  },
  {
    name: 'Withdraws',
    href: '/withdraws',
    icon: NewspaperIcon,
  },
  {
    name: 'UCO Pick-up',
    icon: TruckIcon,
    current: false,
    children: [
      {
        name: 'One Time Pick-up Request Review',
        href: '/uco-pickup',
        current: false,
      },
      {
        name: 'Routine Pick-up Request Overview',
        href: '/uco-routine-pickup',
        current: false,
      },
      { name: 'UCO Pick-up Client List', href: '/uco-client', current: false },
      {
        name: 'Jerry-can Request Review',
        href: '/uco-jerry-can',
        current: false,
      },
      {
        name: 'PWA UCO Setting',
        href: '/uco-setting',
        current: false,
      },
      {
        name: 'Setup Reminder PIC Internal',
        href: '/uco-setting-reminder',
        current: false,
      },
    ],
  },
];

const userNavigation = [{ name: 'Sign out', href: '/logout' }];

const log = logger().child({ module: 'MainNavigator' });

interface Props extends RouteComponentProps {
  title?: string;
  content?: React.ReactChild;
  topButton?: React.ReactNode;
  searchEnabled?: boolean;
  loading?: boolean;

  onSearchChange?(e): void;

  showSortBar?: boolean;
  showFilterBar?: boolean;
  filterContent?: React.ReactChild;

  bgContent?: string;
  titleAboveSearch?: string;
}

export const MainNavigator = withRouter((props: Props) => {
  const profileDetail = useSelector(selectProfileDetail);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigationState, setNavigationState] =
    useState<MenuType[]>(navigationSorter);

  const { actionApi } = useApiSlice();
  const dispatch = useDispatch();
  const api = useSelector(selectApi);

  const setActiveRoute = (route: string) => {
    let idx;
    let idxChild;
    if (items => items.some(value => value.href === route)) {
      idx = navigationState.findIndex((value, index) =>
        !value.children
          ? value.href === route
          : value.children.findIndex(value => value.href === route),
      );
      if (idx < 0) idx = navigationState.length + idx;
      let newArr = [...navigationState];
      if (newArr[idx].current) {
        newArr[idx].current = true;
        if (newArr[idx]?.children) {
          const curr = newArr[idx];
          idxChild = curr.children?.findIndex(value => value.href === route);
          // @ts-ignore
          newArr[idx].children[idxChild].current = true;
        }
        setNavigationState(newArr);
      }
    }
  };

  useEffect(() => {
    const route = props.location.pathname.split('/');
    setActiveRoute(`/${route[1]}`);
  }, []);

  useEffect(() => {
    if (profileDetail.roleId === SUPER_ADMIN_ROLE_ID)
      setNavigationState(navigation);
    if (profileDetail.roleId === ADMIN_ROLE_ID)
      setNavigationState(navigationAdmin);
    if (profileDetail.roleId === OPERATIONAL_ROLE_ID)
      setNavigationState(navigationOperational);
    if (
      profileDetail.roleId === SORTER_ROLE_ID ||
      profileDetail.roleId === PARTNER_SORTER_ROLE_ID
    )
      setNavigationState(navigationSorter);
    if (profileDetail.roleId === OPERATIONAL_ADMIN_ROLE_ID)
      setNavigationState(navigationAdminOps);
  }, [profileDetail]);

  useEffect(() => {
    log.debug(getAuth());
  }, []);

  return (
    <>
      <div className="h-screen w-screen">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 xl:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-dark">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="https://rekosistem.com/wp-content/uploads/2022/09/LOGO-2.png"
                    alt="Rekosistem Logo mobile"
                  />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {navigationState.map(item =>
                      !item.children ? (
                        <div key={item.name}>
                          <a
                            href={item?.href}
                            className={classNames(
                              item.current
                                ? 'bg-indigo-800 text-white'
                                : 'text-indigo-100 hover:bg-indigo-600',
                              'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                            )}
                          >
                            <item.icon
                              className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </div>
                      ) : (
                        <Disclosure
                          as="div"
                          key={item.name}
                          className="space-y-1"
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.current
                                    ? 'bg-indigo-800 text-white'
                                    : 'text-indigo-100 hover:bg-gray-50 hover:bg-indigo-600',
                                  'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
                                )}
                              >
                                <item.icon
                                  className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
                                  aria-hidden="true"
                                />
                                <span className="flex-1">{item.name}</span>
                                <svg
                                  className={classNames(
                                    open
                                      ? 'text-white rotate-90'
                                      : 'text-white',
                                    'ml-3 flex-shrink-0 h-5 w-5 transform transition-colors ease-in-out duration-150',
                                  )}
                                  viewBox="0 0 20 20"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M6 6L14 10L6 14V6Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </Disclosure.Button>
                              <Disclosure.Panel className="space-y-1">
                                {(item.children ?? []).map(subItem => (
                                  <Disclosure.Button
                                    key={subItem.name}
                                    as="a"
                                    href={subItem.href}
                                    className={classNames(
                                      subItem.current
                                        ? 'bg-indigo-800 text-white'
                                        : 'text-indigo-100 hover:bg-gray-50 hover:bg-indigo-600',
                                      'group w-full flex items-center pl-11 pr-2 py-2  text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
                                    )}
                                  >
                                    {subItem.name}
                                  </Disclosure.Button>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ),
                    )}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className="hidden xl:flex xl:w-64 xl:flex-col xl:fixed xl:inset-y-0"
          style={{ zIndex: 99999 }}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div
            className="flex flex-col flex-grow pt-5 overflow-y-auto"
            style={{ backgroundColor: '#024CCE' }}
          >
            <a href={'/'} className="flex items-center flex-shrink-0 px-4 py-4">
              <img
                className="h-14 w-auto mx-auto"
                src={Logo}
                alt="Rekosistem logo"
              />
            </a>
            <div className="mt-5 flex-1 flex flex-col">
              <nav className="flex-1 px-2 pb-4 space-y-1">
                {navigationState.map(item =>
                  !item.children ? (
                    <div key={item.name}>
                      <Link
                        to={item.href ?? '/'}
                        className={classNames(
                          item.current
                            ? 'bg-indigo-800 text-white'
                            : 'text-indigo-100 hover:bg-indigo-600',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                        )}
                      >
                        <item.icon
                          className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </div>
                  ) : (
                    <Disclosure as="div" key={item.name} className="space-y-1">
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              item.current
                                ? 'bg-indigo-800 text-white'
                                : 'text-indigo-100 hover:bg-indigo-600',
                              'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
                            )}
                          >
                            <item.icon
                              className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
                              aria-hidden="true"
                            />
                            <span className="flex-1">{item.name}</span>
                            <svg
                              className={classNames(
                                open ? 'text-white rotate-90' : 'text-white',
                                'ml-3 flex-shrink-0 h-5 w-5 transform transition-colors ease-in-out duration-150',
                              )}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path
                                d="M6 6L14 10L6 14V6Z"
                                fill="currentColor"
                              />
                            </svg>
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1">
                            {(item.children ?? []).map((subItem, index) => (
                              <Link key={index} to={subItem.href ?? '/'}>
                                <Disclosure.Button
                                  key={subItem.name}
                                  as="div"
                                  className={classNames(
                                    subItem.current
                                      ? 'bg-indigo-800 text-white'
                                      : 'text-indigo-100 hover:bg-indigo-600',
                                    'group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pointer-events-none',
                                  )}
                                >
                                  {subItem.name}
                                </Disclosure.Button>
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ),
                )}
              </nav>
            </div>
          </div>
        </div>
        <div className="xl:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-24 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 xl:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">
                <h1 className="text-sm md:text-2xl font-semibold text-gray-900 my-auto">
                  {props?.title ?? 'Dashboard'}
                </h1>
              </div>
              <div className="ml-4 flex items-center xl:ml-6">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="bg-white flex items-center text-sm">
                      <AccountMenu
                        profileData={profileDetail ?? accountDetailDefault}
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map(item => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <button
                              onClick={async () => {
                                await dispatch(actionApi.clearToken());
                                await api.removeToken();
                                const auth = getAuth();
                                await signOut(auth);
                                window.location.assign('/login');
                              }}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'w-full block px-4 py-2 text-sm text-gray-700',
                              )}
                            >
                              {item.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main className="h-full mb-5" style={{ backgroundColor: '#D0E0EC' }}>
            <div className="mx-auto px-4 py-4 sm:px-6 xl:px-8">
              <div className="flex flex-row-reverse mx-auto mt-2 mb-4">
                {props.topButton}
              </div>
              {/* Filters */}
              {props.showSortBar ? (
                <Disclosure
                  as="section"
                  aria-labelledby="filter-heading"
                  className="relative border-t border-b border-gray-200 grid items-center mt-2"
                >
                  <h2 id="filter-heading" className="sr-only">
                    Filters
                  </h2>
                  <div className="relative col-start-1 row-start-1 py-4">
                    <div className="mx-auto flex space-x-6 divide-x divide-gray-200 text-sm px-4 sm:px-6 lg:px-8">
                      <div>
                        <Disclosure.Button className="group text-gray-700 font-medium flex items-center">
                          <FilterIcon
                            className="flex-none w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          Filters
                        </Disclosure.Button>
                      </div>
                      <div className="pl-6">
                        <button type="button" className="text-gray-500">
                          Clear all
                        </button>
                      </div>
                    </div>
                  </div>
                </Disclosure>
              ) : null}
              {props.showFilterBar ? (
                <Disclosure
                  as="section"
                  aria-labelledby="filter-heading"
                  className="relative border-t border-b border-gray-200 grid items-center mt-2"
                >
                  <h2 id="filter-heading" className="sr-only">
                    Filters
                  </h2>
                  <div className="col-start-1 row-start-1 py-4">
                    <div className="flex sm:justify-end mx-auto">
                      {props.filterContent}
                    </div>
                  </div>
                </Disclosure>
              ) : null}
              {/* Replace with your content */}
              <div
                className={`h-full rounded-lg ${props.bgContent ?? 'bg-white'}`}
              >
                <Search
                  show={props.searchEnabled}
                  titleAboveSearch={props.titleAboveSearch}
                  searchEnabled={props.searchEnabled}
                  onSearchChange={props.onSearchChange}
                />
                {props.content}
                <LoadingOverlay isActive={props.loading} />
              </div>
              {/* /End replace */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
});
